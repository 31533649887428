<template>
    <div class="pagebox" v-loading="loading" element-loading-text="加载中...">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 升学资讯 -->
        <div class="risenews">
            <div class="risebox">
                <el-tabs v-model="tabactive" type="card" @tab-click="handleClick">
                    <el-tab-pane label="强基计划" name="0"></el-tab-pane>
                    <el-tab-pane label="综合评价" name="1"></el-tab-pane>
                    <el-tab-pane label="高校专项" name="2"></el-tab-pane>
                    <el-tab-pane label="军校报考" name="3"></el-tab-pane>
                    <el-tab-pane label="定向士官" name="4"></el-tab-pane>
                    <el-tab-pane label="公安院校" name="5"></el-tab-pane>
                    <el-tab-pane label="公费师范" name="6"></el-tab-pane>
                    <el-tab-pane label="优师计划" name="7"></el-tab-pane>
                    <el-tab-pane label="免费医学生" name="8"></el-tab-pane>
                    <el-tab-pane label="港澳高校" name="9"></el-tab-pane>
                    <el-tab-pane label="艺术类报考" name="10"></el-tab-pane>
                    <el-tab-pane label="体育类报考" name="11"></el-tab-pane>
                </el-tabs>
                <div class="clear"></div>
                <div class="riselist">
                    <div class="rbox" v-for="(item,index) in newsdata" :key="index" @click.prevent="$router.push('/risenewsdetail?id='+item.ID)">{{item.Title}}</div>
                    <div class="clear"></div>
                </div>
                <div class="clear"></div>
                <div class="pages" @click.prevent="pagesbtn()">
                    <i class="iconfont" v-if="newsdata.length==0&&pageIndex==1">&#xe67c;</i>
                    <span>{{pagetxt}}</span>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            tabactive:this.$route.query.id+'',
            loading:false,
            pageIndex:1,
            pagetxt:'',
            newsdata:[],
            HasNext:false
        }
    },
    mounted(){
        this.UpgradeNewsList();
    },
    methods:{
        //切换
        handleClick(tab, event) {
            this.pageIndex = 1;
            this.tabactive = tab.index;
            this.newsdata = [];
            this.UpgradeNewsList();
        },
        UpgradeNewsList(){
            this.loading=true;
            API.Web.UpgradeNewsList({PageIndex:this.pageIndex,PageSize:10,UpgradeType:parseInt(this.tabactive)}).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.newsdata.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到资讯信息！';
                }
                this.loading=false;
            });
        },
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.UpgradeNewsList();
                }
            }else{
                if(this.newsdata.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.risenews{
    max-width: 1420px;
    margin: auto;
    .risebox{
        margin: 20px 20px 30px;
        border: 1px solid #dfdfdf;
        background-color: #fff;
        user-select: none;
        min-height: 500px;
        .riselist{
            margin: 10px 20px 30px;
            .rbox{
                margin: 10px 0;
                height: 50px;
                line-height: 50px;
                color: #555;
                font-size: 16px;
                background-color: #f5f5f5;
                text-indent: 20px;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
            }
            .rbox:hover{
                cursor: pointer;
                color: rgb(var(--themecolor));
                background-color: #f1f0f0;
            }
        }
        .pages{
            width: 100%;
            min-height: 50px;
            line-height: 50px;
            color: #666;
            font-size: 16px;
            text-align: center;
            letter-spacing: 1px;
            margin-bottom: 10px;
            user-select: none;
            .iconfont{
                font-size: 50px;
                display: block;
                margin-top: 40px;
                color: #9b9b9b;
            }
        }
        .pages:hover{
            color: rgb(var(--themecolor));
            cursor: pointer;
        }
    }
}
</style>
<style lang="less">
.risenews .risebox .el-tabs--card > .el-tabs__header{
    background-color: #f1f0f0;
}
.risenews .risebox .el-tabs--card > .el-tabs__header .el-tabs__nav{
    border: 1px solid #dddddd;
    border-top: 0px;
    border-left: 0px;
    border-bottom:0px;
    border-radius: 0;
}
.risenews .risebox .el-tabs--card > .el-tabs__header .el-tabs__item{
    border-left: 1px solid #dddddd;
}
.risenews .risebox .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
    border-left:0px;
}
.risenews .risebox .el-tabs__item{
    font-size: 16px;
    color: #555;
}
.risenews .risebox .el-tabs__item.is-active{
    background-color: #fff;
    color: rgb(var(--themecolor));
}
.risenews .risebox .el-tabs__item:focus.is-focus{
    box-shadow: none;
}
.risenews .risebox .el-tabs__nav-wrap.is-scrollable{
    padding-left:31px;
    padding-right: 30px;
    span{
        font-size: 19px;
        color: #666;
        line-height: 40px;
    }
    span:hover{
        color: rgb(var(--themecolor));
    }
    .el-tabs__nav-prev{
        width: 30px;
        border-right: 1px solid #dddddd;
        i{
            margin-left: 5px;
        }
    }
    .el-tabs__nav-next{
        width: 30px;
        border-left: 1px solid #dddddd;
        i{
            margin-left: 5px;
        }
    }
}
</style>